<template>
    <div class="page-vip g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ pageName }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="page-wrap">
                <div class="page-side">
                    <div class="title" @click="showMenu = !showMenu">
                        <img src="@/assets/img/side-service.png" alt="">
                        配送说明
                    </div>
                    <div class="menu" :class="{show:showMenu}">
                        <div class="menu-item" v-for="(item,index) in menu" :key="index">
                            <div class="menu-item-tit"
                                 :class="{active:curPage === item.title}"
                                 @click="jumpTo(item)">
                                <h3>{{item.title}}</h3>
                                <p v-if="item.sub">{{item.sub}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from "@/utils/bus";
import {articleDeliveryPage, saleServiceList} from "@/api/CMSmodule";

export default {
    name: "index",
    data() {
        return {
            showMenu:false,
            curPage:'配送说明',
            pageName:'',
            menu:[]
        }
    },
	mounted() {
		console.log(this.$route.query) // 获取传递参数
		// this.renderCur(this.$route)
		Bus.$off('footer-botService1') // 注销
		Bus.$on("footer-botService1", (info) => { // 接收底部传输
			console.log(info)
			this.curPage = info.title
			Bus.$emit('after-saleService1', info.id)
		})
		//
		if(this.$route.query.id){
			this.getarticleDeliveryPage(this.$route.query.id) // 获取配送说明列表
		}else{
			this.getsaleServiceList() // 获取配送说明列表
		}
	},
	watch:{
		'$route'(val){
			this.renderCur(val)
		}
	},
    methods:{
        jumpTo:function (data) {
			this.curPage = data.title
			Bus.$emit('after-saleService1', data.id)
            // this.$router.push(data.path)
        },
        renderCur:function (route) {
            this.pageName = route.meta.title
            this.menu.map(item => {
                if (item.path === route.path){
                    this.curPage = item.title
                }
            })
        },

		//  -------------------------接口请求
        getarticleDeliveryPage(id){ // 获取配送说明列表
            articleDeliveryPage({}).then((res) => {
                if (res.data.code==200) {
                    this.menu=res.data.data.records
                    console.log(id)
                    if(id){
                        this.menu.forEach(i=>{
                            if(id==i.id){
                                this.curPage = i.title
                                Bus.$emit('after-saleService1', id)
                            }
                        })
                    }else{
                        this.curPage = this.menu[0].title
                        Bus.$emit('after-saleService1', this.menu[0].id)
                    }

                }else{

                }

            })
        },

    },

}
</script>

<style scoped>

</style>
